import React from "react";
import { Layout } from "../../components/Layout";
import { HeaderBuffer } from "../../components/Layout/Header";
import richAndLaurie from "../../images/rich-and-laurie.jpeg";
import curtAndFamily from "../../images/curt-and-family.jpeg";
import codyAndFamily from "../../images/cody-and-family.jpg";
import nancy from "../../images/nancy.png";
import bill from "../../images/bill.jpeg";

const OurLeadersPage = () => {
  return (
    <Layout>
      <main className="min-h-[80vh]">
        <div className="bg-cyan-950">
          <HeaderBuffer />
        </div>
        <div className="w-full p-16 bg-cyan-900">
          <h1 className="text-white text-5xl font-serif text-center md:text-left">
            Our Leaders
          </h1>
        </div>

        <div className="w-full pl-8 md:pl-16 pt-8">
          <h2 className="text-xl font-bold text-cyan-700">The Elders</h2>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 grid-flow-row w-full py-8 px-8 md:px-16 gap-6 md:gap-16">
          <LeaderRow
            img={richAndLaurie}
            name="Rich Hall"
            title="Lead Pastor, Elder"
            description={
              <>
                Rich and Laurie Hall previously ministered at Atwater Community
                Church. Rich grew up in Atwater, and he and Laurie met while he
                attended Trinity College and Seminary in the Chicago area.
                Laurie was raised in the suburbs of Chicago. The two were
                married in 1981 and moved to Colorado where they raised two
                sons, Marc and Ryan, before moving back to NE Ohio in 2012.
                Since they have returned to Ohio, Pastor Rich has been involved
                in Men's Challenge Ministry in Stark County, he served as a
                chaplain for a re-entry home in Hartville, and he has appeared
                in the "Ask a Pastor" show on TCT Television. Pastor Rich also
                has a significant social media bible study and ministry through
                his page{" "}
                <a
                  href="https://www.facebook.com/barnpics"
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue-500 hover:underline"
                >
                  "A Preacher and His Barns"
                </a>
                . You can contact him at{" "}
                <a className="text-blue-500" href="tel:330-614-5490">
                  330-614-5490
                </a>
                .
              </>
            }
          />
          <LeaderRow
            img={curtAndFamily}
            name="Curt Chunat"
            title="Elder"
            description={
              <>
                Curt and his wife Carrie, have attended Wellspring Bible Church
                for the past 10 years. They have five children, Sidney, Mallory,
                Eli, Andy and Johnny. They have a passion for service and
                hospitality.
              </>
            }
          />
          <LeaderRow
            img={codyAndFamily}
            name="Cody Rigney"
            title="Elder"
            description={
              <>
                Cody and his wife, Chrissy, are blessed with 2 children, Cale
                and Carla. Cody's primary vocation is software development in a
                secular company where he hopes to be a light for Christ. He also
                enjoys serving his family, his church, and teaching the Bible.
                As a family they like to go camping, ride bikes, play board
                games, and play video games. Cody's spare time is often spent
                reading, studying the Bible, making things that fly, or writing
                code (whether tinkering with the Raspberry Pi computer or
                building a{" "}
                <a
                  href="https://apps.apple.com/us/app/walk-daily-bible-reading/id1643083018"
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue-500 hover:underline"
                >
                  Bible reading plan app
                </a>
                ).
              </>
            }
          />
        </div>

        <div className="w-full pl-8 md:pl-16 md:pt-16">
          <h2 className="text-xl font-bold text-cyan-700">The Deacons</h2>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 grid-flow-row w-full py-8 px-8 md:px-16 gap-6 md:gap-16">
          <LeaderRow
            img={nancy}
            name="Nancy Hilton"
            title="Deaconess"
            description={
              <>
                Nancy and her husband Greg have attended WellSpring Bible Church
                since 2014 and have been members since 2015. Nancy is currently
                serving as the church treasurer. She has a wealth of knowledge
                and experience in church ministries and 501c3 charitable
                organizations and is pleased to be able to use the knowledge to
                serve God at Wellspring!
              </>
            }
          />
          <LeaderRow
            img={bill}
            name="Bill Saeger"
            title="Deacon"
            description={
              <>
                Bill is a father to one daughter, Brea, who lives in West
                Virginia with her son, Conner. Bill has attended Wellspring
                since becoming a Christian. At that time he was baptized and
                became a member. He has since become a deacon of Wellspring. He
                retired several years ago, but that only lasted 2 months. He
                then went to work part time for another church, where his eyes
                were really opened to what it takes to run a large church. On
                the weekends, he does flea markets. He enjoys being outside and
                selling things one on one. This has led Bill to sharing his
                faith with people. He also enjoys repurposing things. He's
                blessed to have a family and a church family. Both of whom have
                helped him in more ways than they know.
              </>
            }
          />
        </div>
        <div className="w-full bg-sky-800 p-8 md:p-16 md:mt-8">
          <div className="flex flex-col md:flex-row items-start md:items-center gap-4 justify-between">
            <div className=" text-white text-3xl font-light">
              Plan a visit with us!
            </div>
            <a
              href="/about/what-to-expect"
              className="rounded-full  bg-white hover:bg-gray-200 text-cyan-700 text-sm sm:text-lg px-8 py-3"
            >
              What to expect
            </a>
          </div>
        </div>
      </main>
    </Layout>
  );
};

function LeaderRow({ img, name, title, description }) {
  return (
    <>
      <div>
        <img src={img} className="w-full object-cover shadow-xl rounded-3xl" />
      </div>
      <div className="mb-8 md:mb-0">
        <div className="text-4xl text-cyan-900 font-serif">{name}</div>
        <div className="text-lg text-gray-500 font-light">{title}</div>
        <div className="text-md text-cyan-900 mt-2">{description}</div>
      </div>
    </>
  );
}

export default OurLeadersPage;
